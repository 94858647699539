// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
// @import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

@import "~lightgallery/scss/lightgallery.scss";
@import "~lightgallery/scss/lg-thumbnail.scss";
@import "~vanilla-cookieconsent/dist/cookieconsent.css";

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Chelsea Market", system-ui;
    src: url('../fonts/ChelseaMarket-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

body {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-style: normal;
}

// General

// Colors
$primary: #075985;
$danger: rgb(185 28 28);
$warning: rgb(161 98 7);
$success: rgb(20 83 45);

.progress-bar {
    height: 1rem;
}

.chelsea-market-regular {
    font-family: "Chelsea Market", system-ui;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
}

// Icons
.icon-weather {
    img {
        width: 50px;
        height: 50px;
    }
}
.icon-content-size {
    width: 20px;
    height: 20px;
}
.icon-content-extra-size {
    width: 22px;
    height: 22px;
}
@keyframes grow-shrink {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.15);
    }
}
.sun {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="rgb(38,38,38,1)" d="m3.55 19.09l1.41 1.41l1.8-1.79l-1.42-1.42M12 6c-3.31 0-6 2.69-6 6s2.69 6 6 6s6-2.69 6-6c0-3.32-2.69-6-6-6m8 7h3v-2h-3m-2.76 7.71l1.8 1.79l1.41-1.41l-1.79-1.8M20.45 5l-1.41-1.4l-1.8 1.79l1.42 1.42M13 1h-2v3h2M6.76 5.39L4.96 3.6L3.55 5l1.79 1.81zM1 13h3v-2H1m12 9h-2v3h2" /></svg>');
}
.earth {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="white" d="M17.9 17.39c-.26-.8-1.01-1.39-1.9-1.39h-1v-3a1 1 0 0 0-1-1H8v-2h2a1 1 0 0 0 1-1V7h2a2 2 0 0 0 2-2v-.41a7.984 7.984 0 0 1 2.9 12.8M11 19.93c-3.95-.49-7-3.85-7-7.93c0-.62.08-1.22.21-1.79L9 15v1a2 2 0 0 0 2 2m1-16A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2" /></svg>');
}
.chevron-down {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="white" d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6l-6-6z" /></svg>');
}
.umbrella {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="rgb(38,38,38,1)" d="M12 2a9 9 0 0 1 9 9h-8v8a3 3 0 0 1-3 3a3 3 0 0 1-3-3v-1h2v1a1 1 0 0 0 1 1a1 1 0 0 0 1-1v-8H3a9 9 0 0 1 9-9" /></svg>');
}
.train {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2c-4 0-8 .5-8 4v9.5A3.5 3.5 0 0 0 7.5 19L6 20.5v.5h2.23l2-2H14l2 2h2v-.5L16.5 19a3.5 3.5 0 0 0 3.5-3.5V6c0-3.5-3.58-4-8-4M7.5 17A1.5 1.5 0 0 1 6 15.5A1.5 1.5 0 0 1 7.5 14A1.5 1.5 0 0 1 9 15.5A1.5 1.5 0 0 1 7.5 17m3.5-7H6V6h5zm2 0V6h5v4zm3.5 7a1.5 1.5 0 0 1-1.5-1.5a1.5 1.5 0 0 1 1.5-1.5a1.5 1.5 0 0 1 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5" /></svg>');
}
.trolley {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="rgb(38,38,38,1)" d="M13 2v8h8a8 8 0 0 0-8-8m6.32 13.89A7.948 7.948 0 0 0 21 11H6.44L5.5 9H2v2h2.22s1.89 4.07 2.12 4.42C5.24 16 4.5 17.17 4.5 18.5A3.5 3.5 0 0 0 8 22c1.76 0 3.22-1.3 3.46-3h2.08c.24 1.7 1.7 3 3.46 3a3.5 3.5 0 0 0 3.5-3.5c0-1.04-.46-1.97-1.18-2.61M8 20a1.5 1.5 0 0 1-1.5-1.5A1.5 1.5 0 0 1 8 17a1.5 1.5 0 0 1 1.5 1.5A1.5 1.5 0 0 1 8 20m9 0a1.5 1.5 0 0 1-1.5-1.5A1.5 1.5 0 0 1 17 17a1.5 1.5 0 0 1 1.5 1.5A1.5 1.5 0 0 1 17 20" /></svg>');
}
.list {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M3 4h4v4H3zm6 1v2h12V5zm-6 5h4v4H3zm6 1v2h12v-2zm-6 5h4v4H3zm6 1v2h12v-2z" /></svg>');
}
.child-friendly {
    @extend .icon-content-extra-size;
    @extend .trolley;
}
.map {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="rgb(255 255 255)" d="m15 19l-6-2.11V5l6 2.11M20.5 3h-.16L15 5.1L9 3L3.36 4.9c-.21.07-.36.25-.36.48V20.5a.5.5 0 0 0 .5.5c.05 0 .11 0 .16-.03L9 18.9l6 2.1l5.64-1.9c.21-.1.36-.25.36-.48V3.5a.5.5 0 0 0-.5-.5" /></svg>');
}
.chevron-right {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="rgb(7 89 133)" d="M5.59 7.41L7 6l6 6l-6 6l-1.41-1.41L10.17 12zm6 0L13 6l6 6l-6 6l-1.41-1.41L16.17 12z" /></svg>');
}
.chevron-up {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="rgb(255 255 255)" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6l-6 6z" /></svg>');
}
.menu {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M3 6h18v2H3zm0 5h18v2H3zm0 5h18v2H3z" /></svg>');
}
.user {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2M7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.5.88 4.93 1.78A7.893 7.893 0 0 1 12 20c-1.86 0-3.57-.64-4.93-1.72m11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.928 7.928 0 0 1 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.5-1.64 4.83M12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6m0 5a1.5 1.5 0 0 1-1.5-1.5A1.5 1.5 0 0 1 12 8a1.5 1.5 0 0 1 1.5 1.5A1.5 1.5 0 0 1 12 11" /></svg>');
}
.info {
    height: 22px;
    width: 22px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="rgb(7 89 133)" d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2S2 6.5 2 12s4.5 10 10 10M11 7h2v2h-2zm3 10h-4v-2h1v-2h-1v-2h3v4h1z" /></svg>');
}
.info-rock {
    height: 30px;
    width: 30px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="rgb(7 89 133)" d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2S2 6.5 2 12s4.5 10 10 10M11 7h2v2h-2zm3 10h-4v-2h1v-2h-1v-2h3v4h1z" /></svg>');
}
.home {
    height: 16px;
    width: 16px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="rgb(7 89 133)" d="M10 20v-6h4v6h5v-8h3L12 3L2 12h3v8z" /></svg>');
}
.star {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="rgb(202 138 4)" d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.62L12 2L9.19 8.62L2 9.24l5.45 4.73L5.82 21z" /></svg>'); 
}
.exclamation {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="rgb(185 28 28)" d="M10 3h4v11h-4zm0 18v-4h4v4z" /></svg>');
    animation: grow-shrink 4s infinite;
    animation-timing-function: ease-in-out;
}
.skull {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2a9 9 0 0 0-9 9a8.99 8.99 0 0 0 4 7.47V22h2v-3h2v3h2v-3h2v3h2v-3.54c2.47-1.65 4-4.46 4-7.46a9 9 0 0 0-9-9m-4 9a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m8 0a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m-4 3l1.5 3h-3z" /></svg>');
    animation: grow-shrink 4s infinite;
    animation-timing-function: ease-in-out;
}
.chart {
    width: 18px;
    height: 18px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M17.45 15.18L22 7.31V21H2V3h2v12.54L9.5 6L16 9.78l4.24-7.33l1.73 1l-5.23 9.05l-6.51-3.75L4.31 19h2.26l4.39-7.56z" /></svg>');
}
.image-gallery {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="rgb(255 255 255)" d="M22 16V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2m-11-4l2.03 2.71L16 11l4 5H8M2 6v14a2 2 0 0 0 2 2h14v-2H4V6" /></svg>');
}
.loose-rock {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="rgb(185 28 28)" d="M256 32c-17.7 0-32 14.3-32 32v101.9c0 5.6-4.5 10.1-10.1 10.1c-3.6 0-7-1.9-8.8-5.1l-48-83.9C83 123.5 32 199.8 32 288v64h512v-66.4c-.9-87.2-51.7-162.4-125.1-198.6l-48 83.9c-1.8 3.2-5.2 5.1-8.8 5.1c-5.6 0-10.1-4.5-10.1-10.1V64c0-17.7-14.3-32-32-32zM16.6 384C7.4 384 0 391.4 0 400.6c0 4.7 2 9.2 5.8 11.9C27.5 428.4 111.8 480 288 480s260.5-51.6 282.2-67.5c3.8-2.8 5.8-7.2 5.8-11.9c0-9.2-7.4-16.6-16.6-16.6z" /></svg>');
    background-repeat: no-repeat;
    animation: grow-shrink 4s infinite;
    animation-timing-function: ease-in-out;
}
.trash {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="m20.37 8.91l-1 1.73l-12.13-7l1-1.73l3.04 1.75l1.36-.37l4.33 2.5l.37 1.37zM6 19V7h5.07L18 11v8a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2m2 0h8v-6.8L10.46 9H8z" /></svg>');
    background-repeat: no-repeat;
}
.weather {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="rgb(255 255 255)" d="M6 19a5 5 0 0 1-5-5a5 5 0 0 1 5-5c1-2.35 3.3-4 6-4c3.43 0 6.24 2.66 6.5 6.03L19 11a4 4 0 0 1 4 4a4 4 0 0 1-4 4zm13-6h-2v-1a5 5 0 0 0-5-5c-2.5 0-4.55 1.82-4.94 4.19C6.73 11.07 6.37 11 6 11a3 3 0 0 0-3 3a3 3 0 0 0 3 3h13a2 2 0 0 0 2-2a2 2 0 0 0-2-2" /></svg>');
    background-repeat: no-repeat;
}
.railway-station-icon {
    background-color: rgba(211, 211, 211, 0.7); // Light grey background with 70% opacity
    border-radius: 50%;
    border: white 2px solid;
    padding: 5px;
}
.rock-general-info {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M11 7v2h2V7zm3 10v-2h-1v-4h-3v2h1v2h-1v2zm8-5c0 5.5-4.5 10-10 10S2 17.5 2 12S6.5 2 12 2s10 4.5 10 10m-2 0c0-4.42-3.58-8-8-8s-8 3.58-8 8s3.58 8 8 8s8-3.58 8-8"/></svg>');
    background-repeat: no-repeat;
}
.rock-general-description {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M5 3c-1.11 0-2 .89-2 2v14c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2V5c0-1.11-.89-2-2-2zm0 2h14v14H5zm2 2v2h10V7zm0 4v2h10v-2zm0 4v2h7v-2z"/></svg>');
    background-repeat: no-repeat;
}
.rock-general-access {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M11 12H3.5L6 9.5L3.5 7H11V3l1-1l1 1v4h5l2.5 2.5L18 12h-5v8a2 2 0 0 1 2 2H9a2 2 0 0 1 2-2z"/></svg>');
    background-repeat: no-repeat;
}
.rock-general-map {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5M12 2a7 7 0 0 1 7 7c0 5.25-7 13-7 13S5 14.25 5 9a7 7 0 0 1 7-7m0 2a5 5 0 0 0-5 5c0 1 0 3 5 9.71C17 12 17 10 17 9a5 5 0 0 0-5-5"/></svg>');
    background-repeat: no-repeat;
}
.rock-general-alert {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2L1 21h22M12 6l7.53 13H4.47M11 10v4h2v-4m-2 6v2h2v-2"/></svg>');
    background-repeat: no-repeat;
}
.rock-general-images {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M20 4h-3.17L15 2H9L7.17 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2m0 14H4V6h4.05l1.83-2h4.24l1.83 2H20zM12 7a5 5 0 0 0-5 5a5 5 0 0 0 5 5a5 5 0 0 0 5-5a5 5 0 0 0-5-5m0 8a3 3 0 0 1-3-3a3 3 0 0 1 3-3a3 3 0 0 1 3 3a3 3 0 0 1-3 3"/></svg>');
    background-repeat: no-repeat;
}
.rock-general-signs {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M9 5v4h12V5M9 19h12v-4H9m0-1h12v-4H9M4 9h4V5H4m0 14h4v-4H4m0-1h4v-4H4z"/></svg>');
    background-repeat: no-repeat;
}
.rock-general-flowers {
    @extend .icon-content-size;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M3 13a9 9 0 0 0 9 9c0-5-4.03-9-9-9m9-7.5A2.5 2.5 0 0 1 14.5 8a2.5 2.5 0 0 1-2.5 2.5A2.5 2.5 0 0 1 9.5 8A2.5 2.5 0 0 1 12 5.5m-6.4 4.75a2.5 2.5 0 0 0 2.5 2.5c.53 0 1.02-.17 1.4-.44v.19A2.5 2.5 0 0 0 12 15a2.5 2.5 0 0 0 2.5-2.5v-.19c.38.27.87.44 1.4.44a2.5 2.5 0 0 0 2.5-2.5c0-1-.59-1.85-1.43-2.25c.84-.4 1.43-1.26 1.43-2.25a2.5 2.5 0 0 0-2.5-2.5c-.53 0-1.02.16-1.4.44V3.5A2.5 2.5 0 0 0 12 1a2.5 2.5 0 0 0-2.5 2.5v.19c-.38-.28-.87-.44-1.4-.44a2.5 2.5 0 0 0-2.5 2.5c0 .99.59 1.85 1.43 2.25c-.84.4-1.43 1.25-1.43 2.25M12 22a9 9 0 0 0 9-9c-5 0-9 4-9 9"/></svg>');
    background-repeat: no-repeat;
}

// .headline {
//     position: relative;
//     display: inline-block;
// }

// .helmet-icon {
//     @extend .icon-content-size;
//     position: absolute;
//     right: -30px;
//     top: 0;
//     width: 24px;
//     height: 24px;
//     background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="m20.37 8.91l-1 1.73l-12.13-7l1-1.73l3.04 1.75l1.36-.37l4.33 2.5l.37 1.37zM6 19V7h5.07L18 11v8a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2m2 0h8v-6.8L10.46 9H8z" /></svg>');
//     background-repeat: no-repeat;
//     animation: traverse-down 2s infinite;
//     animation-timing-function: ease-in-out;
// }

.table {
    th {
        font-weight: 600;
    }
    th, td {
        padding: .65rem;
    }
}

.scrollable-tabs-container {
    max-width: 1024px;
    z-index: 1000;
    overflow: hidden;
    
    background: white;
}
.scrollable-tabs-container a.active {
   border-bottom: 2px solid $primary;
}
.scrollable-tabs-container svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 50%;
    color: $primary;
    pointer-events: auto;
}

.scrollable-tabs-container ul {
    display: flex;
    gap: 24px;
    margin: 0;
    list-style: none;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
}

.scrollable-tabs-container ul.dragging {
    scroll-behavior: auto;
}
.scrollable-tabs-container ul.dragging a {
    pointer-events: none;
}

.scrollable-tabs-container ul::-webkit-scrollbar {
    display: none;
}

.scrollable-tabs-container a {
    text-decoration: none;
    user-select: none;
    white-space: nowrap;
    font-size: 14px;
}

.scrollable-tabs-container .right-arrow, .scrollable-tabs-container .left-arrow {
    position: absolute;
    height: 100%;
    width: 100px;
    top: 0;
    display: flex;
    align-items: center;
    padding: 0 10px;
    display: none;
    pointer-events: none;
}

.scrollable-tabs-container .right-arrow.active,
.scrollable-tabs-container .left-arrow.active {
    display: flex;
}

.scrollable-tabs-container .right-arrow {
    right: 0;
    background: linear-gradient(to left, #fff 50%, transparent);
    justify-content: flex-end;
}

.scrollable-tabs-container .left-arrow {
    background: linear-gradient(to right, #fff 50%, transparent);
}

a {
    text-decoration: none;
    color: $primary;
}
.btn-primary, .btn-outline-primary {
    border-color: $primary;
}
.btn-outline-primary {
    color: $primary;
}
.btn:hover {
    background-color: $primary;
    border-color: $primary;
}

.accordion-button:not(.collapsed) {
    background-color: white;
    box-shadow: none;
    color: black;
}

.icon-content {
    width: 20px;
    height: 20px;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button::after {
    color: white;
}
.list-group-item.active {
    color: white;
    background-color: $primary;
    border-color: $primary;
}


.scroll-container::-webkit-scrollbar {
    height: 2px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: rgb(7, 89, 133) !important;
    color: white;
}
.error {
    .message-box {
        height: 200px;
        width: 380px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -100px;
        margin-left: 50px;
    }
    .message-box h1 {
        font-size: 60px;
        line-height: 46px;
        margin-bottom: 40px;
    }
    #Polygon-1,
    #Polygon-2,
    #Polygon-3,
    #Polygon-4,
    #Polygon-4,
    #Polygon-5 {
        animation: float 1s infinite ease-in-out alternate;
    }

    #Polygon-2 {
        animation-delay: .2s;
    }

    #Polygon-3 {
        animation-delay: .4s;
    }

    #Polygon-4 {
        animation-delay: .6s;
    }

    #Polygon-5 {
        animation-delay: .8s;
    }

    @keyframes float {
        100% {
            transform: translateY(20px);
        }
    }
}

.image-container {
    width: 100%;
    max-height: 300px;
    /* Adjust the maximum height as needed */
    overflow: hidden;
}

.image-container img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

input[data-autocomplete-target="input"]:focus {
    border: 2px solid $primary;
    box-shadow: none;
}
.list-group-item {
    li:focus-visible {
        outline: none;
    }
    a:-webkit-any-link:focus-visible {
        outline-offset: 0px;
    }
    :focus-visible {
        outline: none;
    }
}
@include media-breakpoint-up(lg) {
    .lg-img-wrap {
        .lg-object {
            max-width: 750px;
            max-height: 750px;
        }
    }
}

.text-truncate-rock-name {
    max-width: 150px;
}
@include media-breakpoint-up(lg) {
    .text-truncate-rock-name {
        max-width: 100%;
    }
    .sticky-filter {
        position: sticky;
        top: 55px;
        z-index: 1000;
    }
}

.form-check-input {
    border-color: #075985;
}

.form-check-input:checked {
    background-color: #15803d;
}

.form-switch .form-check-input {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"><circle r="3" fill="rgba(7, 89, 133, 1)" /></svg>');
}



.inline-gallery-container {
    width: 100%;

    // set 60% height
    height: 0;
    padding-bottom: 65%;
}

.leaflet-top.leaflet-right .info.legend {
    right: 200px;
    /* Adjust this value to move the legend more to the left */
    top: 10px;
    /* Adjust this value to move the legend vertically */
}

.number-circle {
    r: 17px;
}

.number-text {
    font-size: 22px;
}
.number-text:hover {
    cursor: pointer;
}

.end-dot {
    r: 10px;
    fill: white;
    stroke-width: 1px;
}

.stroke-behavior {
    stroke-width: 5;
}
.route-path-border {
    stroke-width: 6;
}

@include media-breakpoint-up(lg) {
    .number-circle {
        r: 15px;
    }
    .number-text {
        font-size: 18px;
    }
    .stroke-behavior {
        stroke-width: 4;
    }
    .route-path-border {
        stroke-width: 5;
    }
    .end-dot {
        r: 7px;
    }
}
.dropdown-toggle::after {
    display: none;
}

.active-item, .list-group-item:hover {
    background-color: rgba(7, 89, 133, 0.9);
}

.active-item a,
.list-group-item:hover a {
    color: white;
}

#form-check-language {
    .form-switch .form-check-input {
        position: relative;
        width: 3rem;
        height: 1.5rem;
        background-color: #e9ecef;
        border: 1px solid #ced4da;
        border-radius: 1.5rem;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    }

    .form-switch .form-check-input::before {
        content: 'DE';
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: 10px;
    }

    .form-switch .form-check-input:checked::before {
        content: 'EN';
        left: 0.5rem;
        right: auto;
    }

    .form-switch .form-check-input::after {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        transition: left 0.15s ease-in-out, right 0.15s ease-in-out;
    }

    .form-switch .form-check-input:not(:checked)::after {
        left: 0.25rem;
    }

    .form-switch .form-check-input:checked::after {
        right: 0.25rem;
    }
}